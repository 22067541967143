import React from 'react';
import './css/Carrousel.css'
import { ReactComponent as Imagem1 } from '../assets/Monitor.svg'
import { ReactComponent as Imagem3 } from '../assets/Lupa.svg'
import { ReactComponent as Imagem4 } from '../assets/Nuvem.svg'
import { ReactComponent as Imagem2 } from '../assets/Smartphone.svg'
import { ReactComponent as Dot1 } from '../assets/1.svg'
import { ReactComponent as Dot2 } from '../assets/2.svg'

import { Fade, ScaleFade, Slide, SlideFade } from '@chakra-ui/react'

const content = [

    {
        title: 'Processo digitalizado',
        text: <h4 className="cardText">
            Todo o processo é digital e automatizado.<br /> Receba notificações sobre os pedidos
        </h4>,
        svg: <Imagem1 />,
    },
    {
        title: 'Use em qualquer lugar',
        text: <h4 className="cardText">Dá pra fazer cotações no escritório, em casa<br /> ou em qualquer lugar do mundo. Você está no controle.</h4>,
        svg: <Imagem2 />,
    },
    {
        title: 'Compare e escolha',
        text: <h4 className="cardText">Você pode comparar produtos, preços e condições<br /> de negociação com diferentes fornecedores.</h4>,
        svg: <Imagem3 />,
    },
    {
        title: 'Operação registrada',
        text: <h4 className="cardText">Nossa plataforma salva todo o histórico na nuvem para você consultar quando quiser.</h4>,
        svg: <Imagem4 />,
    },
]
export function Card(props) {
    return (
        // <Fade  in={true } animateOpacity>

            <div className='cardContainer' >
                <div className="svgContainer">
                <img style={{ width: 140, height: 140 }} src={require('../assets/imperioatacado.jpeg')} alt="" />
                    {/* {content[props.position - 1].svg} */}
                </div>
                <div className="textContainer">
                    <h1 className="cardTitle">
                        {content[props.position - 1].title}
                    </h1>

                    {content[props.position - 1].text}

                </div>
                <div className="indicatorContainer">

                    {props.position == 1 ? <Dot1 /> : <Dot2 />}
                    {props.position == 2 ? <Dot1 /> : <Dot2 />}
                    {props.position == 3 ? <Dot1 /> : <Dot2 />}
                    {props.position == 4 ? <Dot1 /> : <Dot2 />}


                </div>
            </div>
        // </Fade>
    );
}