import React, { useEffect, useState } from 'react';
import ClientInfo from './ClientInfo'
import UserInfo from './UserInfo'
import FirstQuestion from './FirstQuestion'
import StepsBar from './StepsBar'
import {
    Flex,
    Box,
    Heading,
    Text,
    Button
} from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion"

import { useFormik } from 'formik';
import './css/ContactForm.css'
import { ReactComponent as Check } from '../assets/check.svg'

import { ReactComponent as Logo } from '../assets/logocdc.svg'

function ContactForm(props) {
    const [cnpj, setCnpj] = useState();
    const [loadingCnpj, setLoadingCnpj] = useState(false);
    const [cnpjRegistered, setCnpjRegistered] = useState(false);
    const [sendingSubmitRequest, setSendingSubmitRequest] = useState(false)

    const [hasFantasyName, setHasFantasyName] = useState('');
    const [next, setNext] = useState(true);
    const [form, setForm] = useState({
        razao_social: '',
        nome_fantasia: ''
    })

    const [confirm, setConfirm] = useState(false);

    const [info, setInfo] = useState('')
    const [step, setStep] = useState(1);
    const API = process.env.REACT_APP_ENV == 'true' ? process.env.REACT_APP_API_URL : process.env.REACT_APP_LOCALHOST;

    const [cep, setCep] = useState(
        {
            cep: '',
            cepResponse: {
                cep: '',
                logradouro: '',
                complemento: '',
                bairro: '',
                localidade: '',
                uf: ''
            }
        })

    const defaultCep = {
        cep: '',
        cepResponse: {
            cep: '',
            logradouro: '',
            complemento: '',
            bairro: '',
            localidade: '',
            uf: ''
        }
    }
    useEffect(() => {
        searchcep(cep)
    }, [cep])

    function handleGoLastStep(login, password) {
        setInfo('Cadastro realizado! Confirme o seu email para liberar o seu acesso.')
        setStep(4)
        loginToRedirect(login, password)
    }
    async function loginToRedirect(login, password) {
        console.log(login, password)
        let body = {
            senha: password,
            usu_login: login
        }

        fetch('https://api.clubdacotacao.com.br/v3/api/auth', {
            method: 'POST',
            body: JSON.stringify(body),
            headers: {
                'Content-type': 'application/json;',
                'Accept': 'application/json'
            }
        }).then((response) => {
            if (response.ok) {
                return response.json();
            }
            return Promise.reject(response);
        }).then((res) => {
            setTimeout(() => {
                window.location.href =
                    `https://painel.clubdacotacao.com.br/?token=${res.token}`
            }, 5000);
        }).catch(error => {
            // setSendingSubmitRequest(false)
            //setInfo('Erro ao efetuar cadastro')
            // error.json().then((data) => setInfo(Object.values(data.errors)[0][0]))
        });



    }

    const formik = useFormik({
        initialValues: {
            question1: '',
            question2: '',
            question3: '',
            question4: '',
            cnpj: '',
            razao_social: '',
            nome_fantasia: '',
            estado: '',
            cidade: '',
            bairro: '',
            cep: '',
            complemento: '',
            nome: '',
            telefone: '',
            email: '',
            senha: '',
            confirma: '',
        },
        onSubmit: values => {
            let json = {
                "manager": {
                    "password": values.senha,
                    "email": values.email,
                    "phone": values.telefone,
                    "name": values.nome,
                    "login": values.email,
                    "cpf": "",
                },
                "head_office": {
                    "email": values.email,
                    "social_reazon": values.razao_social,
                    "trading_name": values.nome_fantasia,
                    "name": values.nome_fantasia,
                    "cnpj": values.cnpj,
                    "phone": values.telefone,
                    "state": {
                        "id": values.state_id,
                        "name": values.state_name,
                        "fu": values.fu
                    },
                    "city": {
                        "id": values.city_id,
                        "name": "",
                        "est_id": values.state_id
                    },
                    "zip_code": values.cep,
                    "street": values.complemento,
                    "district": values.bairro,
                    "complement": ""
                },
                "resale": {

                },
                "sectors": [
                    {
                        "id": values.question2
                    }
                ],
                "settings": [
                    {
                        "key": "CLIENT_RELEASE_STATUS"
                    }
                ],
                "attendance": {
                    "attendant": {


                    },
                    "status": {

                    }
                },
                "email": values.email,
                "name": values.razao_social ? values.razao_social : values.nome_fantasia,
                "group_name": values.razao_social,
                "cnpj": values.cnpj,
                "trading_name": values.nome_fantasia,
                "contact": values.nome,
                "site_url": "",
                "phone": values.telefone,
                "delivery_type_id": "",
                "order_type": "",
                "closing_method": "",
                "question1": values.question1,
                "question2": '',
                "question3": values.question3,
                "question4": values.question4,
                "categories": [
                    {
                        "id": "",
                        "name": "",
                        "parent_id": "",
                        "internal_code": "",
                        "is_active": "",
                        "created_at": ""
                    }
                ],
                "implanted": 0
            }
            setSendingSubmitRequest(true)
            fetch(API + 'create-user/imperio', {
                method: 'POST',
                body: JSON.stringify(json),
                headers: {
                    'Content-type': 'application/json;',
                    'Accept': 'application/json'
                }
            }).then(function (response) {
                if (response.ok) {
                    return response.json();
                }
                return Promise.reject(response);
            }).then(function (data) {
                handleGoLastStep(values.email, values.senha)
                setSendingSubmitRequest(false)
            }).catch(error => {
                setSendingSubmitRequest(false)
                //setInfo('Erro ao efetuar cadastro')
                error.json().then((data) => setInfo(Object.values(data.errors)[0][0]))
            });
        },
    });



    function search(cnpj) {
        setCnpjRegistered(false)
        setLoadingCnpj(true)

        fetch("https://brasilapi.com.br/api/cnpj/v1/" + cnpj.replace(/\D/g, ""))
            .then(response => {
                if (!response.ok) { throw response }
                return response.json()  //we only get here if there is no error
            })
            .then(data => {
                let data_array = {
                    razao_social: data.razao_social,
                    nome_fantasia: data.nome_fantasia,
                    cnpj: data.cnpj,
                    bairro: data.bairro,
                    cep: data.cep,
                    cidade: data.municipio,
                    complemento: data.descricao_tipo_de_logradouro + ' ' + data.logradouro,
                    numero: data.numero,
                    estado: data.uf,
                    telefone_cnpj: data.ddd_telefone_1,
                }
                setHasFantasyName(data.nome_fantasia)


                for (const [key, value] of Object.entries(data_array)) {
                    formik.setFieldValue(key, value);
                }
                setConfirm(false);

                fetch(API + "estate/" + data_array.estado)
                    .then(response => {
                        if (!response.ok) { throw response }
                        return response.json()
                    })
                    .then(data => {
                        fetch(API + "city/" + data_array.cidade + "/" + data.est_id)
                            .then(response => {
                                if (!response.ok) { throw response }
                                return response.json()
                            })
                            .then(data => {
                                formik.setFieldValue('city_id', data.cid_id);
                            })
                            .catch(err => {
                                err.text().then(errorMessage => {
                                })
                            })
                        formik.setFieldValue('state_id', data.est_id);
                        formik.setFieldValue('state_name', data.est_nome);
                        formik.setFieldValue('fu', data.est_sigla);
                    })
                    .catch(err => {
                        err.text().then(errorMessage => {
                        })
                    })
                setLoadingCnpj(false)
                setNext(false)

                // fetch('https://hml.api.clubdacotacao.com.br/v3/api/external/verify-cnpj?cnpj=00468956000120' + cnpj.replace(/\D/g, ""))
                fetch('https://api.clubdacotacao.com.br/v3/api/external/verify-cnpj?cnpj=' + cnpj.replace(/\D/g, ""))
                    .then(response =>
                        response.json()
                    ).then(
                        res => res.loja && (setCnpjRegistered(res.loja.length > 0))
                        // res => res.loja && (setCnpjRegistered(true))
                    )



            })
            .catch(err => {

                setConfirm(true);
                setLoadingCnpj(false);


            });
    }
    async function searchcep(cep) {
        console.log('-', cep)
        formik.setFieldValue('cep', cep.cep)
    }

    useEffect(() => {
        if (cep.cep.length >= 8) {
            console.log('?', cep)
            let newcep = cep.cep.replace('-', '')
            fetch(`https://viacep.com.br/ws/${newcep}/json`, {
                headers: { 'content-type': 'application/json' }
            },).then(response => response.json())
                .then(res => {
                    console.log('locations...', res)
                    formik.setFieldValue('city_id', res.localidade);
                    formik.setFieldValue('cidade', res.localidade);
                    formik.setFieldValue('rua', res.logradouro);
                    formik.setFieldValue('bairro', res.bairro);
                    setCep({ ...cep, cepResponse: res })
                    getLocationsIds(res)
                    // onChangeUF(res)
                })
        }
    }, [cep.cep])

    function getLocationsIds(loc) {
        console.log('getlocations')
        fetch(process.env.REACT_APP_API_URL + `estate/${loc.uf}`)
            .then((response) => response.json())
            .then((res) => {
                console.log('teste', res)
                let estadoId = res.est_id
                let estadoNome = res.est_nome
                let estadoSigla = res.est_sigla
                console.log('-----?????', res)
                formik.setFieldValue('state_id', estadoId);
                formik.setFieldValue('state_name', estadoNome);
                formik.setFieldValue('estado', estadoNome);
                formik.setFieldValue('fu', estadoSigla);
                // setStateId(estadoId)
                fetch(process.env.REACT_APP_API_URL + `city/${loc.localidade}/${estadoId}`)
                    .then((resp) => resp.json())
                    .then((res) => {

                        let cidadeID = res.cid_id
                        formik.setFieldValue('city_id', cidadeID);
                        setNext(false)
                        // setCityId(cidadeID)
                    }
                    )
            }
            )
    }

    return (
        <div
            style={{
                width: '100%',
                height: '100dvh',
                // maxHeight: 300,
                // overflow:'scroll',
                // display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',


            }}


        // width="full" height={'full'} align="center" justifyContent="center"
        >
            {/* {true? */}
            {step == 4 ?
                <div className='containerSuccessScreen'>


                    <div className='containersvg'>
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 52 52">
                            <circle
                                class="checkmark__circle"
                                cx="26"
                                cy="26"
                                r="25"
                                fill="none" />
                            <path
                                class="checkmark__check"
                                fill="none"
                                d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>

                    </div>

                    <strong className='SuccessScreenTextStrong'>Cadastro realizado !</strong>
                    <p className='SuccessScreenText'><br></br>Você será redirecionado para nosso sistema!</p>






                </div>
                :
                <div style={{
                    backgroundColor: 'white', padding: 15, display: 'flex',
                    flex: 1,


                    height: '100%',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                }}
                // mt={8} 
                >

                    {/* <Box style={{ padding: 15 }} mb={12} textAlign="center">
                            <StepsBar step={step} />
                        </Box> */}

                    <form style={{

                    }} autocomplete="on" onSubmit={formik.handleSubmit}>
                        {(() => {
                            if (step == 1) {
                                // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                //     <img style={{ width: 100, height: 100 }} src={require('../assets/imperioatacado.jpeg')} alt="" />
                                //     {/* <Logo
                                //                 style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)' , marginBottom:15 }}
                                //             ></Logo> */}
                                // </div>
                                return (
                                    <>

                                        <Box flexDir={'row'} mb={7} textAlign="center">
                                            <Heading as='h3' size='lg' fontWeight='normal'>Pare de perder tempo com horas<br /> no telefone!</Heading>
                                        </Box>

                                        <FirstQuestion formik={formik} setStep={setStep} setForm={setForm} />
                                    </>

                                )
                            }
                            if (step == 2) {
                                // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                //     <img style={{ width: 100, height: 100 }} src={require('../assets/imperioatacado.jpeg')} alt="" />
                                //     {/* <Logo
                                //                 style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)', marginBottom:15  }}
                                //             ></Logo> */}
                                // </div>
                                return (
                                    <>
                                        <Box mb={4} textAlign="center">
                                            <Heading as='h4' size='lg' fontWeight='normal'>Dados da empresa principal</Heading>
                                        </Box>
                                        <ClientInfo
                                            cnpjAlreadyRegistered={cnpjRegistered}
                                            confirm={confirm}
                                            formik={formik}
                                            setStep={setStep}
                                            setCep={setCep}
                                            cep={cep}
                                            search={search}
                                            setCnpj={setCnpj}
                                            loadingCnpj={loadingCnpj}
                                            hasFantasyName={hasFantasyName}
                                            cnpj={cnpj}
                                            next={next}
                                            setForm={setForm}
                                            form={form} />
                                    </>
                                )
                            }
                            if (step == 3) {
                                // <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                //     <img style={{ width: 100, height: 100 }} src={require('../assets/imperioatacado.jpeg')} alt="" />
                                //     {/* <Logo
                                //                 style={{ filter: 'invert(47%) sepia(79%) saturate(1580%) hue-rotate(351deg) brightness(100%) contrast(103%)', marginBottom: 15 }}
                                //             ></Logo> */}
                                // </div>
                                return (
                                    <>
                                        <Box mb={7} textAlign="center">
                                            {sendingSubmitRequest ? <Heading as='h3' size='lg' fontWeight='normal'>Efetuando cadastro ...</Heading> :
                                                <Heading as='h6' size='lg' fontWeight='normal'>Você está a um passo de uma <br /> cotação mais simples e prática</Heading>
                                            }
                                        </Box>
                                        <UserInfo info={info} sendingSubmitRequest={sendingSubmitRequest} formik={formik} setStep={setStep} setForm={setForm} form={form} />
                                    </>
                                )
                            }
                            return null;
                        })()}

                    </form>

                </div>
            }
        </div>
    );
}

export default ContactForm;