import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    HStack,
    FormLabel,
    Input,
    Alert,
    AlertIcon,
    AlertDescription,
    Spinner,

} from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion"
import MaskedInput from './MasketInput';

function UserInfo(props) {

    const [confirm, setConfirm] = useState(true);
    const [password1, setPassword1] = useState();
    const [password2, setPassword2] = useState();
    const [submitStatus, setSubmitStatus] = useState(true);

    useEffect(() => {
        if (password1 && password2) {
            if (password1 !== password2) {
                setConfirm(true);
            } else {
                setConfirm(false);
            }
        }
    }, [password2])


   

    return (

        !props.sendingSubmitRequest ? <>
            {/* false ? <> */}
            <Alert
                display={props.info != "" ? 'hidden' : 'none'} status='info'
            >
                <AlertIcon />
                <AlertDescription>{props.info}</AlertDescription>
            </Alert>
            <AnimatePresence >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    <FormLabel color="gray" htmlFor='nome'>Nome</FormLabel>
                    <Input className='inputStyle' onChange={props.formik.handleChange} value={props.formik.values.nome} name='nome' id='nome' type='text' />

                    <FormLabel color="gray" htmlFor='telefone'>Telefone</FormLabel>
                    {/* <Input className='inputStyle' onChange={props.formik.handleChange} value={props.formik.values.telefone} name='telefone' id='telefone' type='number' /> */}
                    <MaskedInput
                        width={'100%'}
                        className='inputStyle'
                        name='telefone'
                        id='telefone'
                        type='number'
                        mask="(99) 9999 99999"
                        value={props.formik.values.telefone}
                        onChange={props.formik.handleChange}
                    />
                    <FormLabel color="gray" htmlFor='email'>Email (esse será seu login de acesso)</FormLabel>
                    <Input className='inputStyle' onChange={props.formik.handleChange} value={props.formik.values.email} name="email" id='email' type='email' />

                    <FormLabel color="gray" htmlFor='senha1'>Senha</FormLabel>
                    <Input className='inputStyle' onChange={(e) => { props.formik.handleChange(e); setPassword1(e.currentTarget.value) }} value={props.formik.values.senha} name='senha' id='senha1' type='password' autocomplete="off" />
                    <FormLabel color="gray" htmlFor='senha2'>Confirma senha</FormLabel>
                    <Input className='inputStyle' onChange={(e) => { props.formik.handleChange(e); setPassword2(e.currentTarget.value) }} value={props.formik.values.confirma} name='confirma' id='senha2' type='password' autocomplete="new-password" />
                    <Alert display={confirm == true ? 'hidden' : 'none'} status='warning'>
                        <AlertIcon />
                        <AlertDescription>As senhas devem ser idênticas</AlertDescription>
                    </Alert>
                    <HStack style={{ justifyContent: 'space-between' }} mt='5'>
                        <Box textAlign="left">
                            <Button onClick={(e) => { props.setStep(2) }} bg='#FF7110' _hover={{ bg: '#e6650e' }} color='white'>Voltar</Button>
                        </Box>
                        <Box textAlign="right">
                            <Button
                                bg='#FF7110' _hover={{ bg: '#e6650e' }} color='white'
                                type='submit'
                                isDisabled={confirm}
                                onClick={() => setSubmitStatus(false)}
                            >
                                Enviar
                            </Button>
                        </Box>
                    </HStack>

                </motion.div>
            </AnimatePresence>
        </> : <>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>

                <Spinner
                    thickness='5px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='#ff7110'
                    size='xl'
                />
            </div>
        </>

    );
}
export default UserInfo;