import React, { useEffect, useState } from 'react';
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Flex,
    Box,
    Heading,
    HStack,
    VStack,
    Button,
    Alert,
    AlertIcon,
    AlertDescription
} from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion"
import MaskedInput from './MasketInput';
import '../Components/css/Carrousel.css'
import { Checkbox, CheckboxGroup, Stack } from '@chakra-ui/react'

function ClientInfo(props) {


    const [checkedItem, setCheckedItem] = useState('cnpj')
    useEffect(() => {

        const { question1, question2, question3, question4 } = props.formik.values;
        props.formik.resetForm({
            values: {
                ...props.formik.initialValues,
                question1,
                question2,
                question3,
                question4,
            },
        });
    }, [checkedItem])
    useEffect(() => {
        const handlePasteAnywhere = event => {
            props.search((event.clipboardData.getData('text') + "").replace(
                /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
                "$1.$2.$3/$4-$5"));

            props.setCnpj(event.clipboardData.getData('text') + "")
        };

        window.addEventListener('paste', handlePasteAnywhere);

        return () => {
            window.removeEventListener('paste', handlePasteAnywhere);
        };
    }, []);

    function address(props) {
        if (props.form.descricao_tipo_de_logradouro) {
            return props.form.descricao_tipo_de_logradouro + ' ' + props.form.logradouro + ', ' + props.form.numero;
        }
        else return '';
    }
    //28645188000144
    return (
        <>
            <AnimatePresence >
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.2 }}
                >
                    <Box
                        my={2} mb={1} textAlign="left"
                    >

                        <Stack spacing={5} mb={4} justifyContent={'space-between'} direction='row'>
                            <Checkbox

                                isChecked={checkedItem == 'cnpj'}
                                onChange={(e) => setCheckedItem('cnpj')}
                            >Cadastro com CNPJ</Checkbox>
                            <Checkbox
                                isChecked={checkedItem == 'cpf'}
                                onChange={(e) => setCheckedItem('cpf')}
                            >
                                Cadastro com CPF
                            </Checkbox>
                        </Stack>
                        <FormControl >
                            {checkedItem == 'cnpj' ?
                                <div className='containerCnpjInput'>
                                    <>
                                        <Box>
                                            <FormLabel htmlFor='cnpj'>CNPJ</FormLabel>
                                            {/* <Input mask="999.999.999-99" className='inputStyle' w='300px' for onChange={(e) => { props.setCnpj(e.currentTarget.value); props.formik.handleChange(e) }} value={props.formik.values.cnpj} name="cnpj" id='cnpj' type='number' /> */}
                                            <MaskedInput
                                                className='inputStyle'
                                                name="cnpj"
                                                mask="99.999.999/9999-99"
                                                value={props.formik.values.cnpj}
                                                onChange={(e) => { props.setCnpj(e.currentTarget.value); props.formik.handleChange(e) }}
                                            />

                                        </Box>
                                        <Box>
                                            <Button mt={1} onClick={(e) => { props.search(props.cnpj) }} justifyContent="flex-end" variant='outline' colorScheme='orange'>Consultar</Button>
                                        </Box>
                                    </>
                                </div>
                                :
                                <Box>
                                    <FormLabel htmlFor='cnpj'>CPF</FormLabel>
                                    {/* <Input mask="999.999.999-99" className='inputStyle' w='300px' for onChange={(e) => { props.setCnpj(e.currentTarget.value); props.formik.handleChange(e) }} value={props.formik.values.cnpj} name="cnpj" id='cnpj' type='number' /> */}
                                    <MaskedInput
                                        className='inputStyle'
                                        name="cnpj"
                                        width={'100%'}
                                        mask="999.999.999.99"
                                        value={props.formik.values.cnpj}
                                        onChange={(e) => { props.setCnpj(e.currentTarget.value); props.formik.handleChange(e) }}
                                    />

                                </Box>

                            }

                        </FormControl>



                        <FormControl mt={1} isDisabled={false}>
                            {checkedItem == 'cnpj' ?
                                <>
                                    <Box>
                                        <FormLabel htmlFor='razao'>Razão Social</FormLabel>
                                        <Input
                                            className='inputStyle'
                                            isDisabled
                                            onChange={props.formik.handleChange}
                                            name="razao_social"
                                            id='razao'
                                            type='text'
                                            value={props.formik.values.razao_social}
                                        />
                                    </Box>
                                    <Box>
                                        <FormLabel htmlFor='nome_fantasia'>Nome Fantasia</FormLabel>
                                        <Input className='inputStyle' isDisabled={!props.hasFantasyName == ''} onChange={props.formik.handleChange} name="nome_fantasia" id='nome_fantasia' type='text' value={props.formik.values.nome_fantasia} />
                                    </Box>

                                    <HStack spacing='15px'>
                                        <Box>
                                            <FormLabel htmlFor='estado'>Estado</FormLabel>
                                            <Input className='inputStyle' isDisabled onChange={props.formik.handleChange} name="estado" id='estado' type='text' value={props.formik.values.estado} />
                                        </Box>
                                        <Box>
                                            <FormLabel htmlFor='cidade'>Cidade</FormLabel>
                                            <Input className='inputStyle' isDisabled onChange={props.formik.handleChange} name="cidade" id='cidade' type='text' value={props.formik.values.cidade} />
                                        </Box>

                                    </HStack>

                                    <HStack spacing='15px'>
                                        <Box>
                                            <FormLabel htmlFor='bairro'>Bairro</FormLabel>
                                            <Input className='inputStyle' isDisabled onChange={props.formik.handleChange} name="bairro" id='bairro' type='text' value={props.formik.values.bairro} />
                                        </Box>
                                        <Box>
                                            <FormLabel htmlFor='cep'>CEP</FormLabel>
                                            <Input
                                                className='inputStyle'
                                                isDisabled
                                                onChange={props.formik.handleChange}
                                                name="cep"
                                                id='cep'
                                                type='text'
                                                value={props.formik.values.cep}
                                            />
                                        </Box>

                                    </HStack>

                                    <FormLabel htmlFor='logradouro'>Complemento</FormLabel>
                                    <Input className='inputStyle' isDisabled id='logradouro' type='text' value={props.formik.values.complemento} />
                                </> :
                                <>
                                    <Box>
                                        <FormLabel htmlFor='nome_fantasia'>Nome</FormLabel>
                                        <Input
                                            className='inputStyle'
                                            onChange={props.formik.handleChange}
                                            name="nome_fantasia"
                                            id='nome_fantasia'
                                            type='text'
                                            value={props.formik.values.nome_fantasia}
                                        />
                                    </Box>
                                    <HStack spacing='15px'>
                                        <Box>
                                            <FormLabel htmlFor='cep'>CEP</FormLabel>
                                            <Input
                                                className='inputStyle'
                                                // onChange={props.formik.handleChange}
                                                onChange={(e) => { props.setCep({ ...props.cep, cep: e.target.value }) }}
                                                name="cep"
                                                id='cep'
                                                type='text'
                                                value={props.formik.values.cep}
                                            />
                                        </Box>
                                        <Box>
                                            <FormLabel htmlFor='estado'>Estado</FormLabel>
                                            <Input className='inputStyle' onChange={props.formik.handleChange} name="estado" id='estado' type='text' value={props.formik.values.estado} />
                                        </Box>

                                    </HStack>

                                    <HStack spacing='15px'>
                                        <Box>
                                            <FormLabel htmlFor='cidade'>Cidade</FormLabel>
                                            <Input className='inputStyle' onChange={props.formik.handleChange} name="cidade" id='cidade' type='text' value={props.formik.values.cidade} />
                                        </Box>
                                        <Box>
                                            <FormLabel htmlFor='bairro'>Bairro</FormLabel>
                                            <Input className='inputStyle' onChange={props.formik.handleChange} name="bairro" id='bairro' type='text' value={props.formik.values.bairro} />
                                        </Box>

                                    </HStack>
                                    <HStack spacing='15px'>
                                        <Box>
                                            <FormLabel htmlFor='numero'>Número</FormLabel>
                                            <Input
                                                className='inputStyle'
                                                onChange={props.formik.handleChange}
                                                name="numero"
                                                id='numero'
                                                type='text'
                                                value={props.formik.values.numero}
                                            />
                                        </Box>
                                        <Box>
                                            <FormLabel htmlFor='logradouro'>Complemento</FormLabel>
                                            <Input className='inputStyle' id='logradouro' type='text' value={props.formik.values.complemento} />
                                        </Box>

                                    </HStack>


                                </>}

                        </FormControl>


                        <Alert display={props.confirm == true ? 'hidden' : 'none'} status='warning'>
                            <AlertIcon />
                            <AlertDescription>Cnpj não encontrado</AlertDescription>
                        </Alert>
                        <Alert display={props.cnpjAlreadyRegistered ? 'hidden' : 'none'} status='warning'>
                            {/* <Alert display={true? 'hidden' : 'none'} status='warning'> */}
                            <AlertIcon />
                            <AlertDescription>Cnpj já cadastrado! Entre em contato com nosso suporte. </AlertDescription>
                            <AlertDescription><a target={'_blank'} href='https://wa.me/5581999845221'>(81)99984-5221</a></AlertDescription>
                        </Alert>
                    </Box>
                    <HStack style={{ justifyContent: 'space-between' }} mt='5' >
                        <Box textAlign="left">
                            <Button onClick={(e) => { props.setStep(1) }} bg='#FF7110' _hover={{ bg: '#e6650e' }} color='white'>Voltar</Button>
                        </Box>
                        <Box textAlign="right">
                            <Button
                                isDisabled={props.next |
                                    props.formik.values.nome_fantasia == '' |
                                    props.cnpjAlreadyRegistered |
                                    checkedItem == 'cpf' ? props.cep.cep.length >= 8 ? false : true : false
                                }
                                isLoading={props.loadingCnpj}
                                onClick={(e) => { props.setStep(3) }}
                                justifyContent="flex-end"
                                bg='#FF7110'
                                _hover={{ bg: '#e6650e' }}
                                color='white'
                            >Próximo</Button>
                        </Box>
                    </HStack>
                </motion.div>
            </AnimatePresence >

        </>
    );
}

export default ClientInfo;